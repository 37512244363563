import { useState, createContext, useEffect } from 'react'
import format from 'date-fns/format'
import { dateFromString } from 'utils/dateUtils'
import useStaylistAPIs from 'components/booking/context/useStaylistAPIs'
import { unitIsPetFriendly, unitIsADAAccessible } from 'components/booking/context/bookingUtils'
import { objectToStorage, objectFromStorage } from 'utils/storageUtils'
import isEqual from 'lodash/isEqual'

export const AvailabilityContext = createContext()

const AvailabilityContextContainer = ({ children }) => {
  const savedData = objectFromStorage('availability')
  const [startDate, setStartDate] = useState(savedData?.startDate ? dateFromString(savedData.startDate) : null)
  const [endDate, setEndDate] = useState(savedData?.endDate ? dateFromString(savedData.endDate) : null)
  const [adultCount, setAdultCount] = useState(savedData?.adultCount ?? 0)
  const [childCount, setChildCount] = useState(savedData?.childCount ?? 0)
  const [bringingDogs, setBringingDogs] = useState(savedData?.bringingDogs ?? false)
  const [adaAccessible, setAdaAccessible] = useState(savedData?.adaAccessible ?? false)
  const [availabilityData, setAvailabilityData] = useState([])
  const [lastSearchParams, setLastSearchParams] = useState({})
  const { checkAvailability } = useStaylistAPIs()

  useEffect(() => {
    let ignore = false

    const initialCheck = async() => {
      const availabilityResult = await checkAvailability()
      if (!ignore) {
        setAvailabilityData(availabilityResult)
      }
    }
    initialCheck()

    return () => { ignore = true }
  }, [checkAvailability])

  useEffect(() => {
    objectToStorage('availability', {
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      adultCount,
      childCount,
      bringingDogs,
      adaAccessible
    })
  }, [startDate, endDate, adultCount, childCount, bringingDogs, adaAccessible])

  const searchAvailability = async(folio) => {
    if (!startDate || !endDate || adultCount === 0) { return }
    const bookingParams = {
      checkin: format(startDate, 'yyyy-MM-dd'),
      checkout: format(endDate, 'yyyy-MM-dd'),
      adults: adultCount,
      children: childCount,
      pets: 0,
      adaAccessible: adaAccessible
    }
    const availabilityResult = await checkAvailability(bookingParams, folio)
    setAvailabilityData(availabilityResult)
    setLastSearchParams({
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      adultCount,
      childCount,
      bringingDogs,
      adaAccessible
    })
    return availabilityResult
  }

  const searchChanged = () => {
    const newSearchParams = {
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      adultCount,
      childCount,
      bringingDogs,
      adaAccessible
    }

    return !isEqual(lastSearchParams, newSearchParams)
  }

  const filteredSiteData = availabilityData
    .filter(data => data.type === 'site')
    .map(data => ({
      ...data,
      searchIsCurrent: !searchChanged(),
      categories: data.categories
        .filter(category => +category.rate_code.highest_rate > 0)
        .map(category => ({
          ...category,
          units: category.units
            .filter(unit => (bringingDogs ? unitIsPetFriendly(unit) : unit))
            .filter(unit => (adaAccessible ? unitIsADAAccessible(unit) : unit))
            .filter(unit => adultCount + childCount <= unit.restrictions?.max_occupancy ?? 8)
        }))
    }))

  return (
    <AvailabilityContext.Provider value={{
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      adultCount,
      setAdultCount,
      childCount,
      setChildCount,
      adaAccessible,
      setAdaAccessible,
      bringingDogs,
      setBringingDogs,
      availabilityData,
      filteredSiteData,
      searchAvailability
    }}
    >
      {children}
    </AvailabilityContext.Provider>
  )
}

export default AvailabilityContextContainer
